import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import GithubLink from "../components/GithubLink"
import ProjectSeries from "../components/ProjectSeries"


import FrameImg from "../images/cs462/frame_built.jpg"
import GantryImg from "../images/cs462/gantry_built.jpg"
import ProtoImg from "../images/cs462/proto_board.jpg"
import TumbImg from "../images/project_thumbnails/cs462.png"
import ImageGallery from "../components/ImageGallery"

const imgs = [
    {
        img: TumbImg,
        alt: "The web UI",
    },
    {
        img: ProtoImg,
        alt: "Bottom side of Protoboard (a wiring mess)",
    },
    {
        img: FrameImg,
        alt: "The frame assembled with gantries",
    },
    {
        img: GantryImg,
        alt: "Close view on an assembled gantry",
    },
]

const cs462Page = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>CSCE 462: Microcomputer Systems</h1>
            {GithubLink("https://github.com/Gautreaux/CSCE462-Project")}
            {ProjectSeries({
                name:"12d Printer",
                prevname: "Undergraduate Research",
                prevlink: "/urs",
                nextname: "12d Printer",
                nextlink: "/12dprinter",
            })}
            <p>CSCE 462 focuses on microcontrollers, single board computers, and using them to realize a product in the physical world. I chose to use this class to spend time developing the physical prototype of my <a href="/12dprinter">12d Printer</a> and integrating some of what I learned from <a href="/sumobots">Sumobots</a> to develop a webapp for controlling a stepper motor. Its a realtively simple system: webserver and websockets server off the Raspberry PI. This is pretty much all the same as in Sumobots.</p>
            <p>The stepper motors are controlled by a A4988 stepper driver. The hardest part was building a board to hold the motor drivers. For the eight motors, eight A4988s were required. Each requires 3 pins to control it and 8 * 3 = 24 is right at the limit of what the Raspberry PI 3B+ has. Adding in the eight limit switches, there were too many GPIO pins, so a MCP2017 extender was used. I made a sample board on protoboard. I naively thought it wouldn't be too bad; in hindsight I wish I had ordered PCBs. The biggest challenge was that the large stepper motors drew up to 2A each and that meant that I needed to be prepared to handle 16A of current. </p>
            <p>There is not much to say about this project that isn't covered elsewhere:</p>
            <ul>
                <li><a href="/12dprinter">12d Printer</a> for an overview of the mechanical design.</li>
                <li><a href="/sumobots">Sumobots</a> for an overview of the software design</li>
                <li><a href="https://github.com/Gautreaux/CSCE462-Project">Github</a> for diving into the code, etc.</li>
            </ul>
            <p>As built images: </p>
            <ImageGallery value={imgs}/>
            {FooterBar()}
        </div>
    )
}

export default cs462Page